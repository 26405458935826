.table{

   .radio,
   .checkbox{
        position: relative;
        height: 20px;
        display: block;
        width: 20px;
        padding: 0px 0px;
        margin: 0px 5px;
        text-align: center;

        .icons{
            left: 5px;
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 8px;
       vertical-align: middle;
   }

    > thead > tr > th{
        border-bottom-width: 1px;
        font-size: $font-size-medium;
        text-transform: uppercase;
        color: $primary-bg;
        font-weight: bold;
        padding-bottom: 5px;
        border-top: none !important;
        border-bottom: none;
        text-align: left !important;
    }

    // >tbody > tr {
    //     cursor: pointer;
    // }

   .td-actions .btn{
        @include opacity(0.36);

        &.btn-xs{
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    .td-actions{
        min-width: 90px;
    }

    > tbody > tr{
        position: relative;

        &:hover{
            .td-actions .btn{
                @include opacity(1);
            }
        }
    }

    .btn:focus{
        box-shadow: none !important;
    }
}
.table-upgrade{
    .table{
        tr{
            td{
                width: 100%
            }
        }
    }
}

.table .form-check .form-check-sign, .table .form-check .form-check-sign:after, .table .form-check .form-check-sign:before {
    margin-top: -16px;
}

.payment-table {
    width: -webkit-fill-available; 
}

.ant-table-cell {
    text-align: center;
    // display: flex;
    // justify-content: center;
}

.ant-table-summary {
    .ant-table-cell {
        background-color: #6f42c12b;
    }
}