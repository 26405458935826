body,
html {
  overflow: hidden;
}

.page-title {
  font-weight: bold;
  margin-top: 0;
}

.btn-primary {
  background-color: $primary-bg;
  color: $white-color;
  font-weight: 500;
  border: 1px solid $primary-bg;
}

.primary-color {
  color: $primary-bg;
}

.btn-primary::hover,
.btn-primary::active,
.btn-primary::focus {
  background-color: $white-color !important;
  color: $primary-bg !important;
}

.profile-label {
  color: #7179a0;
}

.eligibility-list {
  list-style: none;
}

.icon-success {
  color: rgb(63, 204, 63);
}

.icon-danger,
.icon-danger:hover {
  color: red;
}

.login-page-wrap {
  .card {
    border: none;
    width: 350px;
  }
}

.login-page-form-wrap,
.login-page-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: -webkit-fill-available;
}

.login-page-img-wrap {
  background-color: #f3f4f8;
}

.badge {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.875rem;
  padding: 0 20px;
  background-color: #52449f;
  height: 30px;
  width: fit-content;
}

.badge-sm {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
}

.status-badge {
  color: white;
}

.custom-list-wrapper {
  list-style: none;
  padding: 0;
}

.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}

.custom-list-item {
  gap: 20px;
}

// Tabs
.ant-tabs-tab {
  padding: 0;
  margin: 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.ant-tabs-tab,
.ant-tabs-nav-list,
.ant-tabs-tab-btn {
  width: -webkit-fill-available;
}

.ant-table-wrapper {
  width: -webkit-fill-available;
}
.org-filter {
  width: 150px;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-primary {
  background-color: #52449f;
  color: white !important;
  font-weight: 400;
  border: none;
  outline: none;

  &:focus,
  &:hover {
    background-color: #52449f1f;
    border: 1px solid #52449f;
    color: #52449f !important;
  }
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-outline {
  background-color: #52449f1f;
  border: 1px solid #52449f;
  color: #52449f !important;
  font-weight: 400;

  &:focus,
  &:hover {
    background-color: #52449f;
    color: white !important;
  }
}

.color-white {
  color: white !important;
}

.ant-descriptions-item-label {
  font-weight: 700;
}

.error_event {
  color: #ff4d4f;
  border-bottom: 2px solid;
  padding-bottom: 2px;
  cursor: pointer;
}

.success_event {
  color: #28a745;
  border-bottom: 2px solid;
  padding-bottom: 2px;
  cursor: pointer;
}

.warning_event {
  color: orange;
  border-bottom: 2px solid;
  padding-bottom: 2px;
}

.ant-input-number-affix-wrapper {
  width: -webkit-fill-available;
}

@media (max-width: 770px) {
  .loan-detail-tabs .ant-tabs-nav-list {
    flex-direction: column !important;
  }

  .loan-detail-tabs .ant-tabs-nav-list .ant-tabs-tab {
    margin: 0 !important;
  }

  .loan-detail-tabs .ant-tabs-tab-btn {
    padding: 5px 10px;
    border: 1px solid;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #52449f;
    color: black;
    margin-bottom: 5px;
  }

  .loan-detail-tabs [aria-selected="true"] {
    background: #52449f;
    color: white !important;
  }
}

.loading-overlay {
  background: #bcbcbc4c;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1000;
}

.ant-notification-notice-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.daily-pay-list .ant-tabs-tab-btn {
  color: #52449f !important;
  border-color: #52449f !important;
  padding: 6px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  text-shadow: none !important;
  font-weight: bold;
}

.dashboard_table {
  tr {
    cursor: pointer;
  }
}

.ant-drawer-content {
  border-radius: 20px;
}

.ant-descriptions-title {
  color: #52449f !important;
}

.ant-table-summary {
  .ant-table-cell {
    font-weight: bold;
    text-align: center;
    // display: flex;
    // justify-content: center;
  }
}

.ant-table-thead {
  .ant-table-cell {
    font-weight: bold;
    text-align: center;
  }
}

.site-collapse-custom-collapse .ant-collapse-item {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.site-collapse-custom-collapse .ant-collapse-header {
  align-items: center;
}

.site-collapse-custom-collapse .ant-collapse-header > div {
  display: flex;
  align-items: center;
}

.ant-switch-checked {
  background: #52449f;
}

.negative-mt-2 {
  margin-top: -15px;
}

.loan-date-item {
  font-weight: 600;
}

.loading-render-list {
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.custom-list-wrapper {
  // min-height: 150px;
}

.ant-loading-spinner {
  height: 10px;
  width: 10px;
}

.warning-item {
  background: #ff000033;
  color: red;
}

.warning-item i {
  color: red;
}

.ant-popover-message-title {
  padding: 0 !important;
}

.purple {
  color: #52449f;
}

.bg-primary {
  background-color: #52449f !important;
  color: white;
}

@media (max-width: 578px) {
  .calendar_envents {
    display: flex;
    flex-direction: column;
  }

  .calendar_envents > span {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.custom_upload_area {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-upload-remove-btn {
  max-width: 180px !important;
}

.csv-download-btn {
  height: 32px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #d9d9d9;
}
.loan_detail_images {
  .ant-image {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.description-item {
  margin-top: 10px;
  margin-bottom: 10px;

  .description-label {
    align-items: center;
    display: flex;
    margin: 0;
    min-width: 150px;
    font-weight: 900;
    color: #a29f9f;
  }

  .description-value {
    font-weight: bold;
  }
}

h4.ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: bold;
}

.transfer-popover-select {
  min-width: 150px;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.client-detail-header {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.anticon-loading.anticon-spin {
  font-size: x-large;
  color: black;
}

.dashboard-all-org-wrapper {
  display: flex;
  gap: 6px;
  margin: 24px 0;

  .badge {
    font-weight: 400;
  }
}

.gap-2 {
  gap: 6px !important;
}

.pointer {
  cursor: pointer;
}
